/*==========================================
#1.2 footer-area 
==========================================*/

.wpo-site-footer {
    background: #062265;
    position: relative;
    font-size: 15px;
    overflow: hidden;
    /*** about-widget ***/
    /*** link-widget ***/
    /*** resource-widget ***/
    /*** market-widget ***/
    /*** wpo-lower-footer ***/
}

.wpo-ne-footer {
    background: #062265;
}

.wpo-site-footer-2 {
    background: #fcfcfc;
}
.footer-middle{
  padding-top: 100px;
}
.wpo-site-footer ul,
.wpo-site-footer-2 ul {
    list-style: none;
}

.wpo-site-footer p,
.wpo-site-footer li {
    color: #fff;
}

.wpo-site-footer-2 p,
.wpo-site-footer-2 li {
    color: #444444;
}

.wpo-site-footer .container,
.wpo-site-footer-2 .container {
    position: relative;
}

.wpo-site-footer .wpo-upper-footer,
.wpo-site-footer-2 .wpo-upper-footer {
    padding: 40px 0 70px;
    background: url(../../images/footer.jpg)center center/cover no-repeat local;
}

@media (max-width: 991px) {

    .wpo-site-footer .wpo-upper-footer,
    .wpo-site-footer-2 .wpo-upper-footer {
        padding: 70px 0;
      padding-bottom: 0;
    }
}

@media (max-width: 767px) {

    .wpo-site-footer .wpo-upper-footer,
    .wpo-site-footer-2 .wpo-upper-footer {
        padding:40px 0;
    }
}

@media (max-width: 991px) {

    .wpo-site-footer .wpo-upper-footer .col,
    .wpo-site-footer-2 .wpo-upper-footer .col {
        min-height: 235px;
        margin-bottom: 70px;
    }
}

@media (max-width: 767px) {

    .wpo-site-footer .wpo-upper-footer .col,
    .wpo-site-footer-2 .wpo-upper-footer .col {
        min-height: auto;
        margin-bottom: 20px;
    }

    .footer-middle {
        padding-top: 70px;
    }
}

.wpo-site-footer .widget-title,
.wpo-site-footer-2 .widget-title {
    margin-bottom: 30px;
}

@media (max-width: 767px) {

    .wpo-site-footer .widget-title,
    .wpo-site-footer-2 .widget-title {
        margin-bottom: 20px;
    }
}

.wpo-site-footer .widget-title h3,
.wpo-site-footer-2 .widget-title h3 {
    font-size: 23px;
    font-weight: bold;
    color: #fff;
    margin: 0;
    text-transform: capitalize;
    position: relative;
}

.wpo-site-footer-2 .widget-title h3 {
    color: #2d4277;
}

@media (max-width: 991px) {

    .wpo-site-footer .widget-title h3,
    .wpo-site-footer-2 .widget-title h3 {
        font-size: 20px;
        font-size: 1.25rem;
    }
}

.wpo-site-footer .about-widget .logo {
    max-width: 180px;
}


.wpo-site-footer .about-widget ul,
.wpo-site-footer-2 .about-widget ul {
    overflow: hidden;
    padding-top: 10px;
}

.wpo-site-footer .about-widget ul li,
.wpo-site-footer-2 .about-widget ul li {
    font-size: 22px;
    float: left;
}

.wpo-site-footer .about-widget ul>li+li,
.wpo-site-footer-2 .about-widget ul>li+li {
    margin-left: 25px;
}

.wpo-site-footer .about-widget ul a {
    color: #fff;
}

.wpo-site-footer-2 .about-widget ul a {
    color: #404040;
}

.wpo-site-footer .about-widget ul a:hover,
.wpo-site-footer-2 .about-widget ul a:hover {
    color: #08cc7f;
}

.wpo-site-footer-2 .about-widget ul a {
    color: #08cc7f;
}
.wpo-footer-top{
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255,255,255,.10);
}
.wpo-footer-top ul{
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-end;
}
.wpo-footer-top ul li{
  margin-right: 10px;
}
.wpo-footer-top ul li a{
  display: block;
  width: 60px;
  height: 60px;
  background: rgba(255,255,255,.10);
  text-align: center;
  line-height: 65px;
  border-radius: 50%;
  font-size: 20px;
  color: #fff;
  transition: all .3s;
}
.wpo-footer-top ul li a:hover{
  background: rgba(255,255,255);
  color: #1e3054;
}
.wpo-site-footer .link-widget,
.wpo-site-footer-2 .link-widget {
    overflow: hidden;
}

@media screen and (min-width: 1200px) {
    .wpo-site-footer .link-widget {
        padding-left: 20px;
    }

    .wpo-site-footer-2 .link-widget {
        padding-left: 10px;
    }
}

@media (max-width: 1199px) {

    .wpo-site-footer .link-widget,
    .wpo-site-footer-2 .link-widget {
        padding-left: 20px;
    }
}

@media (max-width: 991px) {

    .wpo-site-footer .link-widget,
    .wpo-site-footer-2 .link-widget {
        padding-left: 0;
    }
}

@media (max-width: 767px) {

    .wpo-site-footer .link-widget,
    .wpo-site-footer-2 .link-widget {
        max-width: 350px;
    }
}
@media (max-width: 620px) {
  .wpo-footer-top ul {
      justify-content: flex-start;
  }
}

.wpo-site-footer .wpo-footer-top ul li,
.wpo-site-footer-2 .wpo-footer-top ul li {
    position: relative;
}

.wpo-site-footer .link-widget ul a,
.wpo-site-footer-2 .link-widget ul a {
    color: #fff;
}

.wpo-site-footer-2 .link-widget ul a {
    color: #404040;
}

.wpo-site-footer .link-widget ul a:hover,
.wpo-site-footer-2 .link-widget ul a:hover,
.wpo-site-footer .link-widget ul li:hover:before,
.wpo-site-footer-2 .link-widget ul li:hover:before {
    text-decoration: underline;
}

.wpo-site-footer .link-widget ul>li+li,
.wpo-site-footer-2 .link-widget ul>li+li {
    margin-top: 15px;
}

@media screen and (min-width: 1200px) {

    .wpo-site-footer .resource-widget,
    .wpo-site-footer-2 .resource-widget {
        padding-left: 0px;
    }
}

.wpo-site-footer .contact-ft ul li .fi:before,
.wpo-site-footer-2 .contact-ft ul li .fi:before {
    font-size: 20px;
    margin-right: 15px;
}

.wpo-site-footer .contact-ft ul li,
.wpo-site-footer-2 .contact-ft ul li {
    padding-bottom: 10px;
}

.wpo-site-footer .contact-ft,
.wpo-site-footer-2 .contact-ft {
    margin-top: 20px;
}

.wpo-site-footer .wpo-lower-footer,
.wpo-site-footer-2 .wpo-lower-footer {
    text-align: center;
    position: relative;
    background: #192b4e;
}

.wpo-site-footer .wpo-lower-footer .row,
.wpo-site-footer-2 .wpo-lower-footer .row {
    padding: 20px 0;
    position: relative;
}

.wpo-site-footer .wpo-lower-footer .copyright, 
.wpo-site-footer-2 .wpo-lower-footer .copyright {
    display: inline-block;
    font-size: 15px;
    font-size: 0.9375rem;
    margin: 0;
    text-align: right;
    justify-content: flex-end;
    display: flex;
}
.wpo-site-footer .wpo-lower-footer .term ul{
  text-align: left;
  margin-bottom: 0;
  margin-top: 9px;
}
.wpo-site-footer .wpo-lower-footer .term ul li{
  display: inline-block;
  margin-right: 10px;
}
.wpo-site-footer .wpo-lower-footer .term ul li a{
  color: #fff;
}

@media (max-width: 991px) {

    .wpo-site-footer .wpo-lower-footer .copyright,
    .wpo-site-footer-2 .wpo-lower-footer .copyright {
        float: none;
        display: block;
    }
}

.wpo-site-footer .wpo-lower-footer .copyright a,
.wpo-site-footer-2 .wpo-lower-footer .copyright a {
    color: #fff;
    text-decoration: underline;
}

.wpo-site-footer .news-text h3 {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    margin-top: -5px;
    line-height: 25px;
    margin-bottom: 0px;
  }

.wpo-site-footer .news-text span {
    color: #ccc;
    font-size: 12px;
}
.wpo-site-footer .news-text h2{
  font-size: 20px;
  margin-top: 10px;
  color:#02cb89;
}

.wpo-site-footer .news-text {
    overflow: hidden;
    padding-left: 10px;
}

.wpo-site-footer .news-wrap {
    overflow: hidden;
    margin-bottom: 20px;
}

.wpo-site-footer .news-img {
    float: left;
}
.wpo-site-footer .newsletter-widget form input{
    background-color: transparent;
    height: 50px;
    color: #666;
    padding: 6px 20px;
    border: 1px solid rgba(255,255,255,.5);
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
    position: relative;
}
.wpo-site-footer .newsletter-widget form button{
    background-color: transparent;
    height: 50px;
    color: #fff;
    padding: 6px 20px;
    background: #40E0D0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
    position: relative;
    width: 100%;
    margin-top: 30px;
    text-align: left;
    border:none;
    text-transform: uppercase;
}

.wpo-site-footer .newsletter-widget form button:focus{
    outline: none;
}
.wpo-site-footer .newsletter-widget form button i{
    position: absolute;
    right: 0;
    width: 50px;
    height: 50px;
    background: #00b67a;
    line-height: 50px;
    text-align: center;
    top: 0;
}


@media(max-width: 767px){
.wpo-site-footer .wpo-lower-footer .term ul {
    text-align: center;
}
.wpo-site-footer .wpo-lower-footer .copyright, 
.wpo-site-footer-2 .wpo-lower-footer .copyright{
  justify-content: center;
  text-align: center;
  font-size: 14px;
}
.wpo-site-footer .wpo-lower-footer .term ul li a {
    font-size: 14px;
}
}

/*====================================
#2.0 Home-style-2
=====================================*/

.wpo-about-area .row,
.wpo-about-area-2 .row{
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
}
.wpo-about-area-2 .wpo-about-text{
  text-align: center;
}
.wpo-about-area-2 .wpo-section-title{
  text-align: center;
}
.wpo-about-area-2 .wpo-about-text .btns{
  justify-content: center;
}
.wpo-about-area-2 .wpo-about-text {
    padding-left: 45px;
}
@media(max-width: 991px){
.wpo-about-area .row,
.wpo-about-area-2 .row{
  display: unset;
  align-items: center;
}
.wpo-about-area-2 .wpo-about-text{
  padding-left: 0;
}

}

.payment-channels-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  
  .payment-channels-list li {
    margin-right: 10px;
    margin-bottom: 10px;
    display: inline-block;
  }
  
  .payment-icon {
    width: auto; /* Allows width to scale proportionally */
    height: 35px;
    display: inline-block;
    vertical-align: middle;
  }
  