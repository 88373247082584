/* style.css */

/******************************
  Destination Area
******************************/
.destination-area {
  
    background-color: #f9f9f9;
  }
  
  /******************************
    Section Title
  ******************************/
  .wpo-section-title {
    margin-bottom: 40px;
  }
  
  .wpo-section-title span {
    display: block;
    width: 50px;
    height: 4px;

    margin: 0 auto 10px;
    border-radius: 2px;
  }
  
  .wpo-section-title h2 {
    font-size: 2.5rem;
   
    color: #333;
    margin: 0;
  }
  
  /******************************
    Navigation Menu
  ******************************/
  .origin-navigation {
    margin-bottom: 40px;
    text-align: center;
  }
  
  .origin-navigation .origin-list {
    list-style: none;
    padding: 0;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .origin-navigation .origin-list-item {
    margin: 0 10px;
  }
  
  .origin-navigation .origin-link {
    text-decoration: none;
    color: #40E0D0;
    font-weight: 500;
    transition: color 0.3s ease;
  }
  
  .origin-navigation .origin-link:hover {
    color: #0056b3;
    text-decoration: underline;
  }
  
  /******************************
    Destination Group
  ******************************/
  .destination-group {
    margin-bottom: 60px;
  }
  
  .group-title {
    font-size: 1.8rem;

    color: #40E0D0;
    margin-bottom: 30px;
    text-transform: uppercase;
    text-align: center;
  }
  
  /******************************
    Destination Item
  ******************************/
  .destination-link {
    text-decoration: none;
    color: inherit;
  }
  
  .destination-item {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    background-color: #fff;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .destination-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
  
  .destination-img {
    width: 100%;
    height: 200px;
    overflow: hidden;
  }
  
  .destination-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s;
  }
  
  .destination-img img:hover {
    transform: scale(1.05);
  }
  
  .destination-content {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    background-color: #f8f9fa;
  }
  
  .content-left,
  .content-right {
    display: flex;
    flex-direction: column;
  }
  
  .destination-title {
    font-size: 1rem;
    margin: 0 0 5px 0;

  }
  
  .destination-date {
    font-size: 0.875rem;
    color: #6c757d;
  }
  
  .destination-price {
    font-size: 1rem;
    font-weight: 600;
    color: #28a745;
  }
  
  /******************************
    Responsive Design
  ******************************/
  @media (max-width: 991px) {
    .wpo-section-title h2 {
      font-size: 2.2rem;
    }
  
    .group-title {
      font-size: 1.6rem;
    }
  
    .destination-content {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .content-right {
      margin-top: 10px;
    }
  }
  
  @media (max-width: 767px) {
    .destination-area {
      padding: 40px 0;
    }
  
    .wpo-section-title h2 {
      font-size: 2rem;
    }
  
    .origin-navigation .origin-list {
      flex-direction: column;
      align-items: center;
    }
  
    .origin-navigation .origin-list-item {
      margin: 5px 0;
    }
  
    .destination-img {
      height: 150px;
    }
  
    .group-title {
      font-size: 1.4rem;
    }
  
    .destination-content {
      padding: 10px;
    }
  
    .destination-title,
    .destination-price {
      font-size: 0.95rem;
    }
  
    .destination-date {
      font-size: 0.8rem;
    }
  }
  
  /******************************
    Smooth Scrolling
  ******************************/
  html {
    scroll-behavior: smooth;
  }
  
  /******************************
    Additional Styling (Optional)
  ******************************/
  .destination-area::before {
    content: "";
    display: block;
    height: 1px;
    background: #eaeaea;
    margin-bottom: 40px;
  }
  
  .accordion-item {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    background: #f9f9f9;
  }
  
  .accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
  }
  
  .accordion-icon {
    font-size: 24px;
    font-weight: bold;
  }
  
  .accordion-content {
    background: #fff;
    overflow-y: auto;
    transition: max-height 0.3s ease;
  }
  
  .destination-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .destination-item {
    margin-bottom: 5px;
  }
  
  .destination-item a {
    text-decoration: none;
    color: #40E0D0;
  }
  
  .destination-item a:hover {
    text-decoration: underline;
  }
  