/*===========================================
#0.9 video-banner-area
=============================================*/

.video-banner-area .container,
.video-banner-area-2 .container{
  width: 1410px;
  max-width: 100%!important;
  margin: auto;
}
.video-banner-area,
.video-banner-area-2{
  position: relative;
}
.video-banner-area:before,
.video-banner-area-2:before{
  position: absolute;
  left: 0;
  background: #f5fbf9;
  bottom: 0;
  content: '';
  width: 100%;
  height: 50%;
}

.banner-img{
  position: relative;
}
.banner-img:before{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  content: "";
  background: rgba(0,0,0,.20);
}
.banner-img .video-holder{
  position: relative;
}

.banner-video{
  position: absolute;
  top: 50%;
  left: 50%;
}
.video-holder button.wrap {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: #fff;
    width: 83px;
    height: 83px;
    border-radius: 83px;
    display: inline-block;
    -webkit-animation: spineer 2s infinite;
    animation: spineer 2s infinite;
    z-index: 99;
    border: 1px solid #fff;
}

.video-holder button.wrap:before {
    content: "";
    width: 0px;
    height: 0px;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 20px solid #40E0D0;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

@-webkit-keyframes spineer {
    0% {
        -webkit-box-shadow: 0 0 0 0 #c7c1ea;
    }

    70% {
        -webkit-box-shadow: 0 0 0 20px rgba(1, 93, 199, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(1, 93, 199, 0);
    }
}

@keyframes spineer {
    0% {
        -webkit-box-shadow: 0 0 0 0 #c7c1ea;
        box-shadow: 0 0 0 0 #c7c1ea;
    }

    70% {
        -webkit-box-shadow: 0 0 0 20px rgba(1, 93, 199, 0);
        box-shadow: 0 0 0 20px rgba(1, 93, 199, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(1, 93, 199, 0);
        box-shadow: 0 0 0 0 rgba(1, 93, 199, 0);
    }
}

.video-banner-area .container-fluid{
  padding: 0;
}