ul.smothscroll {
    position: fixed;
    bottom: 25px;
    right:30px;
    list-style: none;
    z-index: 99;
}
ul.smothscroll a {
    background-color: rgb(64, 224, 208);
    width: 45px;
    height: 45px;
    line-height: 45px;
    border: 2px solid rgb(0, 214, 144);
    border-radius: 0;
    display: block;
    text-align: center;
    color: #fff;
    border-radius: 50%;
}
ul.smothscroll a:hover {
    background-color:  rgb(0, 214, 144, 0.7);
}

@media(max-width:767px){
    ul.smothscroll a {
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
    }
}