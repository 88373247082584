
/******************************
  #page title
******************************/
/* Import Reey Font if using @font-face */
@font-face {
  font-family: 'Reey';
  src: url('../../fonts/reey-font/reey-regular-webfont.woff2') format('woff2'),
       url('../../fonts/reey-font/reey-regular-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

.page-title {
  background: url("../../images/ogtix_banner_no_txt.jpg") center center/cover no-repeat local;
  height: 400px;
  text-align: center;
  position: relative;
  margin:0 40px;
  z-index: 1;
}


.page-title:before {
  content: "";
  background: rgba(10,54,94,.3);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.page-title .container {
  height: 100%;
  display: table;
}

.page-title .container > .row {
  vertical-align: middle;
  display: table-cell;
}

.page-title h2,
.page-title ol {
  color: #283a5e;
}

.page-title h2 {
  font-size: 40px;
  font-size: 2.5rem;
  margin-bottom: 0.30em;
  text-transform: capitalize;
  font-weight: 700;
  color: #fff;
  font-family: 'reey', sans-serif;
}

@media (max-width: 991px) {
  .page-title h2 {
    font-size: 35px;
    font-size: 2.1875rem;
    margin-top: 30px;
  }

  .page-title {
    height: 310px;
    margin: 0;
  }

}

@media (max-width: 767px) {
  .page-title h2 {
    font-size: 30px;
    font-size: 1.875rem;
    margin-top: 20px;
  }
   .page-title {
    height: 250px;
  }
}

.page-title .breadcrumb {
  background-color: transparent;
  padding: 0;
  margin-bottom: 0;
  justify-content: center;
}

@media (max-width: 767px) {
  .page-title .breadcrumb {
    text-align: center;
  }
}

.page-title .breadcrumb li {
  font-size: 16px;
  font-size: 1rem;
  color: #fff;
  margin-right: 5px;
  position: relative;
}

@media (max-width: 767px) {
  .page-title .breadcrumb li {
    font-size: 14px;
    font-size: 0.875rem;
  }
}

.page-title .breadcrumb li a {
  color: #fff;
}

.page-title .breadcrumb li a:hover {
  color: #40E0D0;
}

.page-title .breadcrumb > li + li {
  margin-left: 20px;
  padding-left: 15px;
}

.page-title .breadcrumb > li + li:before {
  content: "/";
  color: #fff;
  padding: 0;
  position: absolute;
  left: -8px;
  top: 2px;
}
