/*------------------------------------
wpo-blog sidebar
------------------------------------*/
.wpo-blog-sidebar {
  /*** search-widget ***/
  /*** category-widget ***/
  /*** recent-post-widget ***/
  /*** quote-widget ****/
  /*** tag-widget ***/
}

@media screen and (min-width: 992px) {
  .wpo-blog-sidebar {
      padding-left: 0px;
  }
}

@media (max-width: 991px) {
  .wpo-blog-sidebar {
      margin-top: 80px;
      max-width: 400px;
  }
}

@media (max-width: 991px) {
  .wpo-blog-sidebar-2 {
      margin-top: 0;
      max-width: 400px;
      margin-bottom: 80px;
  }
}

@media (max-width: 767px) {
  .wpo-blog-sidebar {
      margin-top: 60px;
      max-width: 400px;
  }
}

@media (max-width: 767px) {
  .wpo-blog-sidebar-2 {
      margin-top: 0;
      max-width: 400px;
      margin-bottom: 80px;
  }
}


.wpo-blog-sidebar .widget h3 {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 700;
  position: relative;
  text-transform: capitalize;
  padding-bottom: 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid #ebebeb;
}
.wpo-blog-sidebar .widget h3:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 60px;
  height: 4px;
  background: #40E0D0;
}

.wpo-blog-sidebar>.widget+.widget {
  margin-top: 65px;
}

.wpo-blog-sidebar .search-widget {
  border: 0;
  padding: 0;
}

.wpo-blog-sidebar .search-widget form div {
  position: relative;
}

.wpo-blog-sidebar .search-widget input {
  height: 50px;
  font-size: 16px;
  font-size: 1rem;
  border: 1px solid #ebebeb;
  padding: 6px 50px 6px 20px;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
}

.wpo-blog-sidebar .search-widget input:focus {
  background-color: rgba(0, 198, 209, 0.1);
}

.wpo-blog-sidebar .search-widget form button {
  background: #40E0D0;
  font-size: 20px;
  font-size: 1.25rem;
  color: #fff;
  border: 0;
  outline: 0;
  width: 50px;
  height: 50px;
  line-height: 54px;
  position: absolute;
  right: 0;
  top: 0;
}

.wpo-blog-sidebar .category-widget ul {
  list-style: none;
}

.wpo-blog-sidebar .category-widget ul li {
  font-size: 15px;
  font-size: 0.9375rem;
  position: relative;
  padding-left: 20px;
  padding-bottom: 10px;
}

.wpo-blog-sidebar .category-widget ul li:before {
  content: "";
  background: #bbb;
  width: 8px;
  height: 8px;
  position: absolute;
  left: 0;
  top: 4px;
  border-radius: 50%;
}

@media (max-width: 767px) {
  .wpo-blog-sidebar .category-widget ul li {
      font-size: 14px;
      font-size: 0.875rem;
  }
}

.wpo-blog-sidebar .category-widget ul li span {
  position: absolute;
  right: 0;
}

.wpo-blog-sidebar .category-widget ul>li+li {
  margin-top: 12px;
}

.wpo-blog-sidebar .category-widget ul a {
  display: block;
  color: #777;
}

.wpo-blog-sidebar .category-widget ul a:hover {
  color: #40E0D0;
}

.wpo-blog-sidebar .category-widget ul li:hover:before {
  background: #40E0D0;
}

.wpo-blog-sidebar .recent-post-widget .post {
  overflow: hidden;
}

.wpo-blog-sidebar .recent-post-widget .posts>.post+.post {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #f2f0f0;
}

.wpo-blog-sidebar .recent-post-widget .post .img-holder {
  width: 68px;
  float: left;
}

.wpo-blog-sidebar .recent-post-widget .post .details {
  width: calc(100% - 68px);
  float: left;
  padding-left: 20px;
}

.wpo-blog-sidebar .recent-post-widget .post h4 {
  font-size: 16px;
  line-height: 1.3em;
  margin: 0 0 0.3em;
  color: #444444;
}

@media (max-width: 1199px) {
  .wpo-blog-sidebar .recent-post-widget .post h4 {
      margin: 0;
  }
}

.wpo-blog-sidebar .recent-post-widget .post h4 a {
  display: inline-block;
  color: #242f6c;
}

@media (max-width: 1199px) {
  .wpo-blog-sidebar .recent-post-widget .post h4 {
      font-size: 14px;
  }
}

.wpo-blog-sidebar .recent-post-widget .post h4 a:hover {
  color: #40E0D0;
}

.wpo-blog-sidebar .recent-post-widget .post .details .date {
  font-size: 14px;
  color: #888;
}

.wpo-blog-sidebar .recent-post-widget .post .details .date i {
  display: inline-block;
  padding-right: 7px;
}

.wpo-blog-sidebar .quote-widget {
  border: 0;
  padding: 0;
}

.wpo-blog-sidebar .quote-widget .quote {
  background-color: #f5f5f5;
  padding: 30px;
}

.wpo-blog-sidebar .quote-widget .quote p {
  margin: 0;
}

.wpo-blog-sidebar .quote-widget i {
  display: block;
}

.wpo-blog-sidebar .quote-widget .fi:before {
  font-size: 35px;
}

.wpo-blog-sidebar .quote-widget .quoter {
  position: relative;
  padding: 25px 25px 25px 110px;
}

.wpo-blog-sidebar .quote-widget .quoter .img-holder {
  position: absolute;
  left: 0;
}

.wpo-blog-sidebar .quote-widget .quoter img {
  padding: 5px;
  border: 1px solid #dcdcdc;
  border-radius: 50%;
}

.wpo-blog-sidebar .quote-widget .quoter h4 {
  font-size: 16px;
  font-size: 1rem;
  font-weight: bold;
  margin: 0 0 0.3em;
  padding-top: 1.3em;
}

.wpo-blog-sidebar .quote-widget .quoter h4+p {
  font-size: 14px;
  font-size: 0.875rem;
  margin: 0;
}

.wpo-blog-sidebar .tag-widget ul {
  overflow: hidden;
  list-style: none;
}

.wpo-blog-sidebar .tag-widget ul li {
  float: left;
  margin: 0 8px 8px 0;
}

.wpo-blog-sidebar .tag-widget ul li a {
  font-size: 14px;
  display: inline-block;
  padding: 10px 22px;
  color: #525252;
  border: 1px solid #ebebeb;
  border-radius: 0;
}

@media(max-width: 1200px) {
  .wpo-blog-sidebar .tag-widget ul li a {
     padding: 10px 13px;
         font-size: 14px;
  }
}

.wpo-blog-sidebar .tag-widget ul li a:hover {
  background: #40E0D0;
  color: #fff;
}

.instagram ul {
display: -webkit-flex;
display: -moz-flex;
display: -ms-flex;
display: -o-flex;
display: flex;
  flex-wrap: wrap;
  margin: 0px -3px;
}

.instagram ul li {
  -ms-flex: 0 0 33.33%;
  flex: 0 0 33.33%;
  max-width: 33.33%;
  margin-bottom: 5px;
  padding: 0px 3px;
}

.instagram ul li img {
  width: 100%;
}



.profile-img{
  padding: 40px;
  }
  .profile-img img{
  border-radius: 50%;
  margin-bottom: 20px;
  }
  .profile-img h2{
  font-size: 25px;
  font-weight: 700;
  }
  .profile-img p{
  margin-bottom: 0;
  }
  .profile-widget{
  text-align: center;
  background: #f5fbf9;
  border: 1px solid #ebebeb;
  }
  .pro-social ul{
  display: flex;
  justify-content: center;
  padding: 20px 0;
  background: #eefbf7;
  border-top: 1px solid #ebebeb;
  margin-bottom: 0;
  }
  .pro-social ul li{
  margin: 0 10px;
  }
  .pro-social ul li a{
  color: #283a5e;
  }
  .wpo-blog-sidebar {
    padding-left: 30px;
  }
  @media(max-width: 767px){
  .post-bloquote p {
    font-size: 15px!important;
  }
  .post-text-wrap {
    padding: 20px;
  }
  
  }
  @media(max-width: 991px){
  .wpo-blog-sidebar {
    padding-left: 0px;
  }
  }
  
  .get-intouch{
  text-align: center;
  background: url(../../images/blog/phuket-bus.jpg) no-repeat center center;
  position: relative;
  z-index: 1;
  object-fit: cover;
  max-width: 345px;
  }
  .get-item{
  padding: 40px 30px;
  }
  .get-intouch:before{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: #000;
  opacity: .40;
  z-index: -1;
  }
  .get-intouch h2{
  font-size: 35px;
  line-height: 50px;
  color: #fff;
  margin-bottom: 40px;
  font-weight: 700;
  }
  
  @media(max-width: 1200px){
  .get-intouch h2 {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 20px;
  }
  }