/*--------------------------------------------------------------
#0.4 wpo-hero-style
--------------------------------------------------------------*/
/*--------------------------------------------------------------
0.4 hero-slider
--------------------------------------------------------------*/

/* Import Reey Font if using @font-face */
@font-face {
    font-family: 'Reey';
    src: url('../../fonts/reey-font/reey-regular-webfont.woff2') format('woff2'),
         url('../../fonts/reey-font/reey-regular-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

/* Base styles for hero slider */
.hero {
    position: relative;
    height: 40vh; /* Relative height for desktop */
    transition: height 0.3s ease-in-out;
    /* Additional styles */
}

.hero .slide {
    height: 40vh;
    position: relative;
    background-repeat: no-repeat;
}

.slide-inner {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between; /* Distribute space between caption and image */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding: 20px;
}

.hero-style-1,
.hero-style-2,
.hero-style-3 {
    height: 40vh;
    position: relative;
    overflow: hidden;
}

.hero-style-1 {
    margin: 0 40px;
}

/* Header (h2) Styles */
.hero-style-1 .slide-caption h2,
.hero-style-2 .slide-caption h2,
.hero-style-3 .slide-caption h2 {
    color: #fff; /* Make header text white */
    font-size: 4.5rem; /* 32px */
    font-weight: 1500;
    line-height: 2.2; /* 30px */
    margin: 0 0 0.3em;
    font-family: 'Reey', sans-serif; /* Apply Reey font */
}

@media (max-width: 1199px) {
    .hero-style-1 .slide-caption h2,
    .hero-style-2 .slide-caption h2,
    .hero-style-3 .slide-caption h2 {
        font-size: 1.75rem; /* 28px */
    }
}

@media (max-width: 991px) {
    .hero-style-1 .slide-caption h2,
    .hero-style-2 .slide-caption h2,
    .hero-style-3 .slide-caption h2 {
        font-size: 1.5rem; /* 24px */
    }
}

@media (max-width: 767px) {
    .hero-style-1 .slide-caption h2,
    .hero-style-2 .slide-caption h2,
    .hero-style-3 .slide-caption h2 {
        font-size: 1.25rem; /* 20px */
        line-height: 1.5; /* 24px */
    }
}

/* Subheader (p) Styles */
.hero-style-1 .slide-caption p,
.hero-style-2 .slide-caption p,
.hero-style-3 .slide-caption p {
    color: #fff; /* Make subheader text white */
    font-size: 1.2rem; /* 16px */
    margin: 0 0 1em;
    font-family: 'Reey', sans-serif; /* Apply Reey font */
}

@media (max-width: 1199px) {
    .hero-style-1 .slide-caption p,
    .hero-style-2 .slide-caption p,
    .hero-style-3 .slide-caption p {
        font-size: 1rem; /* 14px */
    }
}

@media (max-width: 991px) {
    .hero-style-1 .slide-caption p,
    .hero-style-2 .slide-caption p,
    .hero-style-3 .slide-caption p {
        font-size: 1rem; /* 12px */
    }
}

@media (max-width: 767px) {
    .hero-style-1 .slide-caption p,
    .hero-style-2 .slide-caption p,
    .hero-style-3 .slide-caption p {
        font-size: 1rem; /* 10px */
    }
}

/* Slider Image Styles */
.hero-style-1 .slider-pic,
.hero-style-2 .slider-pic,
.hero-style-3 .slider-pic {
    position: absolute;
    bottom: -15%;
    right: -110%;
    z-index: -1;
    max-width: 340px;
}

@media (max-width: 991px) {
    .hero-style-1 .slider-pic,
    .hero-style-2 .slider-pic,
    .hero-style-3 .slider-pic {
        max-width: 170px;
        right: -60%;
        bottom: 8px;
    }
}

@media (max-width: 767px) {
    .hero-style-1 .slider-pic,
    .hero-style-2 .slider-pic,
    .hero-style-3 .slider-pic {
        display: none; /* Hide images on mobile */
    }
}

/* Hero Text Styles */
.hero-style-3 .hero-text {
    margin-top: 1.25rem; /* 20px */
}

@media (max-width: 991px) {
    .hero-style-3 .hero-text {
        margin-top: 0.625rem; /* 10px */
    }
}

.hero-style-3 .hero-text > span {
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.3125rem; /* 5px */
    font-size: 0.875rem; /* 14px */
    font-family: 'Reey', sans-serif; /* Apply Reey font */
}

.hero-style-3 .hero-text h2 {
    font-size: 1.25rem; /* 20px */
    font-weight: bold;
    color: #fff;
    margin: 0.5em 0 0.8em;
    font-family: 'Reey', sans-serif; /* Apply Reey font */
}

@media (max-width: 991px) {
    .hero-style-3 .hero-text h2 {
        font-size: 1.125rem; /* 18px */
    }
}

@media (max-width: 767px) {
    .hero-style-3 .hero-text h2 {
        font-size: 1rem; /* 16px */
    }
}

/* Slider Controls */
.hero .slick-prev,
.hero .slick-next {
    background-color: rgb(64,224,208);
    width: 55px;
    height: 55px;
    z-index: 10;
    border-radius: 50%;
    transition: all 0.5s;
}

.hero .slick-prev:hover,
.hero .slick-next:hover {
    background-color: rgb(2, 214, 144);
}

.hero .slick-prev {
    left: -100px;
}

.hero .slick-prev:before {
    font-family: "themify";
    content: "\e629";
    opacity: 1;
}

.hero .slick-next {
    right: -100px;
}

.hero .slick-next:before {
    font-family: "themify";
    content: "\e628";
    opacity: 1;
}

.hero:hover .slick-prev {
    left: 25px;
}

.hero:hover .slick-next {
    right: 25px;
}

/* Slider Dots */
.hero .slick-dots {
    bottom: 30px;
}

@media screen and (min-width: 992px) {
    .hero .slick-dots {
        display: none !important; /* Hide dots on larger screens */
    }
}

.hero .slick-dots li {
    margin: 0;
}

.hero .slick-dots button {
    background-color: #40E0D0;
    width: 14px;
    height: 14px;
    border: 2px solid #fff;
    border-radius: 50%;
}

.hero .slick-dots button:before,
.hero .slick-dots button:after {
    display: none;
}

.hero .slide-caption > div {
    overflow: hidden;
}

@media (min-width: 767px) {
    .hero .slide-caption {
        padding-bottom: 100px;
    }
}

.slide-caption .btns .theme-btn {
    margin-right: 30px;
}

.slide-caption .btns {
    margin-top: 30px;
}

/* Animation Effects */
@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translateX(-50px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translateX(50px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeOutLeft {
    from {
        opacity: 1;
        transform: translateX(0);
    }
    to {
        opacity: 0;
        transform: translateX(-50px);
    }
}

@keyframes fadeOutRight {
    from {
        opacity: 1;
        transform: translateX(0);
    }
    to {
        opacity: 0;
        transform: translateX(50px);
    }
}

.hero .slide-caption > div * {
    animation: fadeOutLeft 1.5s both;
}

.hero .slide-caption > .slider-pic * {
    animation: fadeOutRight 1.5s both;
}

.hero .slide-caption > div.slide-title * {
    animation-delay: 0s;
}

.hero .slide-caption > div.slide-subtitle * {
    animation-delay: 0s;
}

.hero .slide-caption > div.btns * {
    animation-delay: 0s;
}

.hero .hero-slider .slick-current .slide-caption > div * {
    animation-name: fadeInLeft;
}

.hero .hero-slider .slick-current .slide-caption > .slider-pic * {
    animation-name: fadeInRight;
}

.hero .hero-slider .slick-current .slide-caption > div.slide-title * {
    animation-delay: 0.5s;
}

.hero .hero-slider .slick-current .slide-caption > div.slide-subtitle * {
    animation-delay: 1s;
}

.hero .hero-slider .slick-current .slide-caption > div.btns * {
    animation-delay: 1.5s;
}

.hero .hero-slider .slick-current .slide-caption > div.slider-pic * {
    animation-delay: 2s;
}

/*--------------------------------------------------------------
Media Query for Landscape Orientation
--------------------------------------------------------------*/
@media (orientation: landscape) and (max-width: 991px) {
    .hero {
        height: 35vh; /* Adjusted height for tablets in landscape */
    }

    .hero .slide,
    .hero-style-1,
    .hero-style-2,
    .hero-style-3 {
        height: 35vh; /* Match .hero height */
    }

    /* Adjust header font size for landscape mode */
    .hero-style-1 .slide-caption h2,
    .hero-style-2 .slide-caption h2,
    .hero-style-3 .slide-caption h2 {
        font-size: 1.75rem; /* 28px */
    }

    /* Adjust subheader font size for landscape mode */
    .hero-style-1 .slide-caption p,
    .hero-style-2 .slide-caption p,
    .hero-style-3 .slide-caption p {
        font-size: 0.875rem; /* 14px */
    }

    /* Adjust slider image size for landscape mode */
    .hero-style-1 .slider-pic,
    .hero-style-2 .slider-pic,
    .hero-style-3 .slider-pic {
        max-width: 170px; /* Reduced from 340px */
        right: -60%;
        bottom: 8px;
    }

    /* Hide slider controls in landscape mode if needed */
    .hero .slick-prev,
    .hero .slick-next {
        display: none !important;
    }

    /* Adjust hero-text margin for landscape mode */
    .hero-style-3 .hero-text {
        margin-top: 0.625rem; /* 10px */
    }

    /* Adjust hero-text header font size for landscape mode */
    .hero-style-3 .hero-text h2 {
        font-size: 1.125rem; /* 18px */
    }
}

/************************************************
 hero-style-1
**************************************************/
.hero-style-1 .slide .slide-caption h2,
.hero-style-2 .slide .slide-caption h2,
.hero-style-3 .slide .slide-caption h2 {
    /* Updated earlier */
}

.hero-style-1 .slide-caption p,
.hero-style-2 .slide-caption p,
.hero-style-3 .slide-caption p {
    /* Updated earlier */
}

.hero-style-1 .slider-pic,
.hero-style-2 .slider-pic,
.hero-style-3 .slider-pic {
    /* Updated earlier */
}

.hero-style-3 .hero-text {
    /* Updated earlier */
}

@media (max-width: 991px) {
    /* Updated earlier */
}

.hero-style-3 .hero-text > span {
    /* Updated earlier */
}

.hero-style-3 .hero-text h2 {
    /* Updated earlier */
}

@media (max-width: 767px) {
    /* Updated earlier */
}

/* Remove unnecessary before pseudo-elements if not needed */
.hero-bg-slider:before {
    display: none;
}
