/* style.css */
.searchSection {
    /* Add styles for the search section container */
    padding: 20px;
  }
  
  .searchWrapper {
    text-align: center;
  }
  
  .searchHeader {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
  }
  
  /* Button-specific styles */
  #busx-search-form button {
    background-color: #40e0d0 !important; /* Override the CSS variable */
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  #busx-search-form button:hover {
    background-color: #2db3a8; /* Slightly darker shade on hover */
  }  