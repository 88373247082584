/*==============================================
#0.7 destination-area
================================================*/

.destination-area {
  background: #f5fbf9;
  padding-top: 20px; /* Reduced from 230px */
  padding-bottom: 20px; /* Added padding-bottom */
}

.destination-area .container {
  max-width: 1200px; /* Adjusted container width */
  margin: 0 auto;
}

.destination-item {
  position: relative;
  margin-bottom: 10px; /* Reduced from 30px */
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.destination-img img {
  width: 100%;
  height: 180px; /* Fixed height for consistency */
  object-fit: cover; /* Ensures images are cropped appropriately */
}

.destination-content {
  padding: 10px; /* Reduced from 20px */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-left h5 {
  color: #283a5e;
  font-size: 16px; /* Reduced from 22px */
  font-weight: 700;
  margin: 0;
}

.content-left h5 a {
  color: #283a5e;
  text-decoration: none;
}

.content-left h5 a:hover {
  color: #02cb89;
}

.content-left small {
  color: #687693;
  font-size: 10px; /* Reduced from 12px */
}

.content-right h5 {
  color: #02cb89;
  font-size: 18px; /* Reduced from 25px */
  margin: 0;
}

.content-right span {
  color: #616f8c;
  font-size: 10px; /* Reduced from 14px */
}

.btns {
  margin-top: 20px;
}

.btns a {
  /* Adjust button styles as needed */
}

@media (max-width: 992px) {
  .destination-area {
    padding-top: 30px; /* Reduced padding for smaller screens */
    padding-bottom: 0px;
  }
}

@media (max-width: 767px) {
  .destination-item {
    margin-bottom: 5px; /* Further reduce margin on small screens */
  }

  .destination-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .content-right {
    margin-top: 10px;
  }
}
