.blog-area .container {
    width: 1425px;
    max-width: 100%!important;
}

.blog-content {
    padding-top: 20px;
    color: #333; /* Default text color */
}

.blog-content h3 {
    font-size: 30px;
    line-height: 47px;
    margin-bottom: 20px;
    margin-top: 15px;
    color: #000;
}

.blog-content p {
    color: #333;
    line-height: 1.6;
}

.blog-content ul li {
    display: inline-block;
    padding: 0 10px;
    color: #333;
}

.blog-content ul li a {
    color: #666;
    transition: all .3s;
    font-weight: 400;
    text-decoration: none;
}

.blog-content a {
    color: #008080;
    font-weight: 700;
    text-decoration: underline;
}

.blog-content a:hover {
    color: #005f5f;
    text-decoration: none;
}

.blog-img img {
    max-width: 100%;
    height: auto;
    transition: all 0.5s;
}

.blog-img img:hover {
    filter: grayscale(50%);
}
